/**
 * Created by jiachenpan on 16/11/18.
 */

/* 合法uri*/
export function validateURL(textval) {
  const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return urlregex.test(textval)
}

/* 小写字母*/
export function validateLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/* 大写字母*/
export function validateUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/* 大小写字母*/
export function validatAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}

//只能输入由数字、26个英文字母或者下划线组成的字符串："^\w+$"
export function strongUserName(s)
{
  // var patrn = /^(?=.*\d)(?=.*[a-z]).{3,20}$/;
  var patrn = /^\w+$/;
  if (!patrn.exec(s)) return false;
  return true
}

/**
 * 验证邮箱
 * @param str
 * @returns {boolean}
 */
export function validatEmail(str) {
  const reg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
  return reg.test(str)
}
 //正数（包括小数)
/**
 * 验证正数（包括小数)
 * @param str
 * @returns {boolean}
 */
export function validatPositive (str) {
  const reg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
  return reg.test(str);
}

/*非0正整数*/
export function noZeroInit(s) {
  var patrn = /^[1-9]\d*$/;
  if(!patrn.test(s)){ return false };
  return true;
}
// 正整数
/**
* 验证正整数（包含0)
* @param str
* @returns {boolean}
*/
export function validatpositiveInt (str) {
  const reg = /^[1-9]\d*$/;
  return reg.test(str);
}
// 0到100正数
/**
* 验证正整数（包含小数点)
* @param str
* @returns {boolean}
*/
export function validatohundred (str) {
  const reg = /^(0|\d{1,2}|100)(\.\d+)?$/;
  return reg.test(str);
}
// 大于零正数
/**
* 验证正整数（包含小数点)
* @param str
* @returns {boolean}
*/
export function validatgreater (str) {
  const reg = /^[1-9]\d*(\.\d+)?$/;
  return reg.test(str);
}

export function getByteLen(val) {
  var len = 0;
  for (var i = 0; i < val.length; i++) {
    var a = val.charAt(i);
    if (a.match(/[^\x00-\xff]/ig) != null) {//\x00-\xff→GBK双字节编码范围
      len += 2;
    }
    else {
      len += 1;
    }
  }
  return len;
}
export function isMobile(s)
{
  var patrn=/^1[3|4|5|6|7|8|9]\d{9}$/;
  if (!patrn.test(s)) return false
  return true
}

export function limitMt4Number(inputString) {
  return inputString.replace(/[^(0-9)]/g, '');
}
